import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Cell from './Cell'

const HeaderCellRightBase = styled(Cell)`
  border-bottom: ${p => p.theme.border.thin};

  @media print {
    border-bottom: none;
    text-align: left;
    font-size: ${p => p.theme.print.fonts.size.bigger}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.big};
    padding-left: ${p => p.theme.print.spacing.cell.padding}px;
    
    // Target the input inside
    input {
      text-align: left;
      font-size: ${p => p.theme.print.fonts.size.bigger}px;
      line-height: ${p => p.theme.print.fonts.lineHeight.big};
      padding-left: 0;
    }
  }
`

const HeaderCellRight = ({children, style, className, id}) => (
  <HeaderCellRightBase id={id} style={style} className={className} column={2}>
    {children}
  </HeaderCellRightBase>
)

HeaderCellRight.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string
}

export default HeaderCellRight
