import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {IconArrowRight} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'
import Row from './Row'

const FactRowBase = styled.div`
  ${p => p.theme.fonts.family.default};
  font-size: ${p => p.theme.fonts.size.normal}px;

  @media print {
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.normal};
  }
`

const Label = styled.div`
  color: ${p => p.theme.colors.darkGray};
  font-weight: ${p => p.theme.fonts.weight.bold};

  @media print {
    font-size: ${p => p.theme.print.fonts.size.normal}px;
  }
`

const Value = styled.div`
  float: right;
  text-align: right;
  display: flex;
  flex-direction: row;
  color: ${p => p.theme.colors.gray};
  font-weight: ${p => p.theme.fonts.weight.normal};
  align-self: center;
  margin-right: ${p => p.showIconRight && '-15px'};

  @media print {
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    margin-right: 0;
  }
`

const FactRowIcon = styled(Icon)`
  margin-left: -12px;

  @media print {
    display: none;
  }
`

const iconRenderMap = {
  arrow: IconArrowRight,
  award: `${process.env.PUBLIC_URL}/assets/icons/check-positive.svg`,
  noAward: `${process.env.PUBLIC_URL}/assets/icons/check-negative.svg`
}

const FactRow = ({
  iconRightType,
  id,
  isFirst,
  isLast,
  label,
  onClick,
  showIconRight = false,
  render
}) => {
  return (
    <FactRowBase id={id}>
      <Row isFirst={isFirst} isLast={isLast} onClick={onClick}>
        <Label>{label}</Label>
        <Value showIconRight={showIconRight}>
          {render()}
          {showIconRight && (
            <FactRowIcon
              src={iconRenderMap[iconRightType]}
              width="60px"
              height="60px"
            />
          )}
        </Value>
      </Row>
    </FactRowBase>
  )
}

FactRow.propTypes = {
  iconRightType: PropTypes.string,
  id: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  showIconRight: PropTypes.bool,
  render: PropTypes.func
}

export default FactRow
