import styled from 'styled-components'

const HeaderRow = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns:
    minmax(${p => p.theme.sizes.dataGridLeftColumnWidth}px, 2fr)
    5fr;
  grid-template-rows: 59.5px;

  @media print {
    grid-template-columns:
      minmax(${p => p.theme.print.sizes.dataGridLeftColumnWidth}px, 1fr) 
      9fr;
    grid-template-rows: ${p => p.theme.print.sizes.dataGridRowHeight}px;
    width: 100%;
    max-width: 100%;
  }
`

export default HeaderRow
