import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Cell from './Cell'

const CellRightBase = styled(Cell)`
  border-bottom: ${p => p.theme.border.thin};

  @media print {
    padding: 2px 0;
  }
`

const CellRight = ({className, children, style}) => (
  <CellRightBase className={className} column={4} style={style}>
    {children}
  </CellRightBase>
)

CellRight.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object
}

export default CellRight
