import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const CellInputBase = styled.input.attrs({type: 'text', size: 1})`
  ${p => p.theme.fonts.default};
  border: 3px solid rgba(255, 0, 0, 0);
  box-sizing: border-box;
  flex: 1;
  font-size: 18px;
  height: 59px;
  margin-right: ${p => p.theme.border.width.thin}px;
  outline: none;
  width: ${p => p.width};
  background: none;

  &:focus {
    border-style: solid;
    border-radius: 3px;
    border-color: ${p => p.theme.colors.blue};
  }

  @media print {
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.normal};
    height: ${p => p.theme.print.sizes.dataGridRowHeight}px;
    padding: ${p => p.theme.print.spacing.cell.padding}px 0;
    margin: ${p => p.theme.print.spacing.cell.margin}px 0;
  }
`

const CellInput = ({
  autoFocus,
  className,
  disabled,
  editMode,
  id,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  readOnly,
  style,
  tempValue,
  value,
  width
}) => (
  <CellInputBase
    autoFocus={autoFocus}
    className={className}
    disabled={disabled}
    id={id}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    onKeyDown={e => e.keyCode === 13 && onKeyDown && onKeyDown(e)}
    placeholder={placeholder}
    readOnly={readOnly}
    style={style}
    value={editMode ? tempValue : value}
    width={width}
  />
)

CellInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  id: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  style: PropTypes.object,
  tempValue: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string
}

export default CellInput
