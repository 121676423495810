import round from 'lodash/round'
import upperFirst from 'lodash/upperFirst'
import PropTypes from 'prop-types'
import React, {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import countries from '../../../assets/data/countries.json'
import {CONFIGURATION_KEYS} from '../../../utils/componentUtils'
import {getScore} from '../../../utils/indicatorUtils'
import BoxItem from '../../core/components/BoxItem'
import Button from '../../core/components/Button'
import FactRow from '../../core/components/FactRow'
import RowHeader from '../../core/components/RowHeader'
import RowValue from '../../core/components/RowValue'
import IndicatorGroup from '../../indicators/components/IndicatorGroup'

const ComponentDetailsBase = styled.div``

const ComponentDetailsWrapper = styled.div`
  position: relative;
  z-index: 2;
`

const ComponentDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-rows: auto auto;
  ${p => p.theme.fonts.family.default};
  grid-column-gap: 20px;
  align-items: 'start';
  margin-top: 35px;
  height: 60%;

  @media print {
    grid-column-gap: ${p => p.theme.print.spacing.cell.padding * 2}px;
    margin-top: ${p => p.theme.print.spacing.cell.padding}px;
    height: auto;
  }
`
const ComponentDetailsButton = styled(Button)`
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  margin-top: 0;
`

const GridItem = styled.div`
  grid-column: ${p => p.col} / ${p => p.col};
  grid-row: ${p => p.row} / ${p => p.row};
  justify-self: ${p => (p.col === 1 ? 'end' : 'start')};
  margin-bottom: ${p => p.header && '15px'};
  padding: 5px 0px;
  font-weight: ${p => p.bold && p.theme.fonts.weight.bold};

  @media print {
    margin-bottom: ${p => p.header && p.theme.print.spacing.cell.margin}px;
    padding: ${p => p.theme.print.spacing.cell.padding}px 0;
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.normal};
  }
`

const RowBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Spacer = styled.div`
  height: 20px;

  @media print {
    height: ${p => p.theme.print.spacing.cell.padding}px;
  }
`

const ComponentDetailsBoxItem = styled(BoxItem)`
  @media print {
    display: none;
  }
`

const ComponentDetails = ({
  cloudIsAvailable,
  component,
  ingredientMatched,
  componentAmountString,
  onMouseDown,
  open,
  servings,
  visibleIndicatorsOnComponentLevel
}) => {
  const [t, {language}] = useTranslation()

  if (!open || !component) {
    return null
  }

  const indicatorMap = {
    co2: {
      labelFirstRow: t('componentDetails.co2RowLabel'),
      labelSecondRow: t('factRows.co2.labelSecondRow'),
      id: 'ComponentDetailsCo2Row',
      renderFirstRow: function co2Row() {
        return (
          <IndicatorGroup
            indicatorType="co2"
            score={
              cloudIsAvailable && ingredientMatched
                ? getScore(component.co2Rating)
                : 0
            }
          />
        )
      },
      renderSecondRow: function valueUnitRow() {
        const co2PerServing = Number(component.co2Value) / servings
        const precision = co2PerServing < 10 ? 2 : 0
        return (
          <RowValue>{`${round(co2PerServing, precision) || 0}${t(
            `indicatorDetails.unit.co2`
          )}`}</RowValue>
        )
      }
    },
    health: {
      labelFirstRow: t('componentDetails.healthRowLabel'),
      id: 'ComponentDetailsHealthRow',
      renderFirstRow: function healthRow() {
        return (
          <IndicatorGroup
            indicatorType="health"
            score={
              cloudIsAvailable && ingredientMatched
                ? getScore(component.vitaScoreRating)
                : 0
            }
          />
        )
      }
    },
    water: {
      labelFirstRow: t('componentDetails.waterRowLabel'),
      labelSecondRow: t('factRows.water.labelSecondRow'),
      id: 'ComponentDetailsWaterRow',
      renderFirstRow: function waterRow() {
        return (
          <IndicatorGroup
            indicatorType="water"
            score={
              cloudIsAvailable && ingredientMatched
                ? getScore(component.waterScarcityRating)
                : 0
            }
          />
        )
      },
      renderSecondRow: function valueUnitRow() {
        return (
          <RowValue>{`${round(Number(component.waterScarcity) / servings, 2) ||
            0}${t(`indicatorDetails.unit.water`)}`}</RowValue>
        )
      }
    }
  }

  const data = [
    {
      labelFirstRow: t('componentDetails.energyRowLabel'),
      id: 'ComponentDetails',
      renderFirstRow: function energyRow() {
        return (
          <RowBase id="ComponentDetailsEnergyRowValue">
            <RowValue>
              {cloudIsAvailable && ingredientMatched
                ? String(round(Number(component.kCal) / servings)) || '0'
                : '0'}{' '}
              kcal
            </RowValue>
          </RowBase>
        )
      }
    },
    ...visibleIndicatorsOnComponentLevel.map(
      indicatorType => indicatorMap[indicatorType]
    ),
    {
      labelFirstRow: upperFirst(t('factRows.weight.label')),
      id: 'ComponentDetailsAmountRow',
      renderFirstRow: function amountRow() {
        return componentAmountString
      }
    }
  ]

  const visibleConfigurationData = ['name', ...CONFIGURATION_KEYS]
    .filter(field => component[field])
    .map(field => {
      let keyValueTuple = [t(`productConfiguration.label.${field}`)]
      if (field === 'origin') {
        keyValueTuple = [
          ...keyValueTuple,
          countries[component[field]][language]
        ]
      } else if (field === 'producer') {
        keyValueTuple = [...keyValueTuple, component[field]]
      } else {
        keyValueTuple = [
          ...keyValueTuple,
          t(`productConfiguration.${field}.${component[field]}`)
        ]
      }

      return keyValueTuple
    })

  return (
    <ComponentDetailsBase id="ComponentDetailsBase">
      <ComponentDetailsWrapper>
        <ComponentDetailsGrid>
          <GridItem header col={1} row={1}>
            <ComponentDetailsBoxItem
              id="ComponentDetailsBoxItem"
              title={component.title}
              imageUrl={component.imageUrl}
              imageType={
                component.component.type === 'product' ? 'icon' : 'image'
              }
            />
          </GridItem>
          <GridItem header col={2} row={1}>
            <ComponentDetailsButton
              data-cy="componentDetailsButton"
              onMouseDown={onMouseDown}
              full
              blue
            >
              {t(`componentDetails.buttonText`)}
            </ComponentDetailsButton>
          </GridItem>
          {component.configured
            ? visibleConfigurationData.map((rowData, index) => {
                let [key, value] = rowData

                return (
                  <React.Fragment key={key}>
                    <GridItem bold col={1} row={index + 2}>
                      {upperFirst(key)}
                    </GridItem>
                    <GridItem col={2} row={index + 2}>
                      {upperFirst(value)}
                    </GridItem>
                  </React.Fragment>
                )
              })
            : null}
        </ComponentDetailsGrid>
      </ComponentDetailsWrapper>
      <Spacer />
      <Spacer />
      <RowHeader>{t('factRows.headerText')}</RowHeader>
      {data.map(
        (
          {id, labelFirstRow, labelSecondRow, renderFirstRow, renderSecondRow},
          index
        ) => (
          <Fragment key={index}>
            <FactRow
              id={id}
              isLast={!renderSecondRow && index === data.length - 1}
              label={labelFirstRow}
              render={renderFirstRow}
            />
            {renderSecondRow && (
              <FactRow
                id={id}
                isLast={index === data.length - 1}
                label={labelSecondRow}
                render={renderSecondRow}
              />
            )}
          </Fragment>
        )
      )}
    </ComponentDetailsBase>
  )
}

ComponentDetails.propTypes = {
  cloudIsAvailable: PropTypes.bool,
  component: PropTypes.object,
  componentAmountString: PropTypes.string,
  ingredientMatched: PropTypes.bool.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  servings: PropTypes.number,
  visibleIndicatorsOnComponentLevel: PropTypes.array.isRequired
}

export default ComponentDetails
