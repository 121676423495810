import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import CellInput from './CellInput'
import CellSelect from './CellSelect'

const AmountSetterBase = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-end;

  @media print {
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.normal};
    height: ${p => p.theme.print.sizes.dataGridRowHeight}px;
  }
`

const AmountSetterCellInput = styled(CellInput)`
  padding-right: 2px;
  text-align: end;

  @media print {
    width: 100px;  // Make amount input even smaller in print
    padding-right: 3px;
  }
`

const Option = styled.option`
  @media print {
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.normal};
  }
`

const AmountSetter = ({
  amount,
  editingAmount,
  onAmountBlur,
  onAmountChange,
  onAmountFocus,
  onUnitFocus,
  onUnitChange,
  tempAmount,
  unit,
  unitOptions
}) => (
  <AmountSetterBase>
    <AmountSetterCellInput
      onFocus={onAmountFocus}
      onChange={onAmountChange}
      onBlur={onAmountBlur}
      value={editingAmount ? tempAmount : amount}
      width="60px"
    />
    <CellSelect
      value={unit}
      onFocus={onUnitFocus}
      onChange={onUnitChange}
      width="60px"
    >
      {unitOptions
        ? unitOptions.map((unitOption, index) => (
            <Option key={index}>{unitOption}</Option>
          ))
        : null}
    </CellSelect>
  </AmountSetterBase>
)

AmountSetter.propTypes = {
  amount: PropTypes.string.isRequired,
  editingAmount: PropTypes.bool.isRequired,
  onAmountBlur: PropTypes.func,
  onAmountChange: PropTypes.func,
  onAmountFocus: PropTypes.func,
  onUnitFocus: PropTypes.func,
  onUnitChange: PropTypes.func,
  tempAmount: PropTypes.string,
  unit: PropTypes.string,
  unitOptions: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default AmountSetter
