import styled from 'styled-components'

const Cell = styled.div`
  align-items: center;
  display: flex;
  grid-column: ${p => p.column};
  grid-row: ${p => p.row};
  height: ${p => p.theme.sizes.dataGridRowHeight}px;
  padding-left: ${p => p.theme.sizes.componentRowPadding}px;

  @media print {
    height: ${p => p.theme.print.sizes.dataGridRowHeight}px;
    padding: ${p => p.theme.print.spacing.cell.padding}px;
    border: none !important;
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.normal};
  }
`

export default Cell
