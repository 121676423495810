import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import RowHeader from '../../core/components/RowHeader'
import Cell from '../../dataGrid/components/Cell'
import InstructionEditorContainer from '../containers/InstructionEditorContainer'
import InstructionTitleInputContainer from '../containers/InstructionTitleInputContainer'

const InstructionsBase = styled.div`
  @media print {
    margin-left: 30px;
  }
`

const InstructionsRowHeader = styled(RowHeader)`
  margin-left: 60px;

  @media print {
    margin-left: 0;
  }
`

const InstructionsGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  justify-content: stretch;
  grid-template-columns: ${p => p.theme.sizes.dataGridLeftColumnWidth}px auto;
  grid-template-rows: repeat(${props => props.numRows}, auto);
  border-top: ${p => p.theme.border.thin};
  border-bottom: ${p => p.theme.border.thin};
  margin-left: 60px;
  margin-right: 60px;

  @media print {
    margin-left: 0;
    margin-right: 0;
  }
`

const CaptionCell = styled(Cell)`
  border-top: ${p => p.borderTop && p.theme.border.thin};
  display: flex;
  grid-column: ${p => p.column};
  grid-row: ${p => p.row};
  box-sizing: border-box;
`

const InstructionCell = styled.div`
  border-top: ${p => p.borderTop && p.theme.border.thin};
  border-left: ${p => p.theme.border.thin};
  display: flex;
  box-sizing: border-box;
  grid-column: ${p => p.column};
  grid-row: ${p => p.row};
  outline: none;
  margin-right: ${p => p.theme.sizes.componentRowOuterColumsWidth}px;
  min-height: ${p => p.theme.sizes.instuctionCellMinHeight}px;

  @media print {
    min-height: calc(${p => p.theme.print.fonts.size.normal}px * ${p => p.theme.print.fonts.lineHeight.normal} * 3);
    border: none;
    margin-right: 0;
  }
`

const Instructions = ({instructions = []}) => {
  const [t] = useTranslation()

  const numRows = instructions.length
  return (
    <InstructionsBase>
      <InstructionsRowHeader>{t('instructions.header')}</InstructionsRowHeader>
      <InstructionsGrid id="InstructionsGrid" numRows={numRows}>
        {instructions.map(({title: value, instruction}, index) => {
          const isFirst = index === 0
          return [
            <CaptionCell
              borderTop={!isFirst}
              key={`caption${index}`}
              row={index + 1}
              column={1}
            >
              <InstructionTitleInputContainer
                editedInstructionIndex={index}
                value={value}
              />
            </CaptionCell>,
            <InstructionCell
              borderTop={!isFirst}
              key={`instruction${index}`}
              row={index + 1}
              column={2}
            >
              <InstructionEditorContainer
                editedInstructionIndex={index}
                instruction={instruction}
              />
            </InstructionCell>
          ]
        })}
      </InstructionsGrid>
    </InstructionsBase>
  )
}

Instructions.propTypes = {
  instructions: PropTypes.array
}

export default Instructions
