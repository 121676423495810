import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const CellSelectBase = styled.select`
  ${p => p.theme.fonts.default};
  align-self: center;
  appearance: none;
  background: none;
  border: 3px solid rgba(255, 0, 0, 0);
  border-radius: 3px;
  box-sizing: border-box;
  flex: 1;
  font-size: 18px;
  height: 59px;
  outline: none;
  padding-left: 2px;
  width: 40px;

  &:focus {
    border-color: ${p => p.theme.colors.blue};
  }

  @media print {
    font-size: ${p => p.theme.print.fonts.size.normal}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.normal};
    height: ${p => p.theme.print.sizes.dataGridRowHeight}px;
    padding: ${p => p.theme.print.spacing.cell.padding}px 0;
    width: 25px;
    border: none;
  }
`

const CellSelect = ({children, onChange, onFocus, value}) => (
  <CellSelectBase onChange={onChange} onFocus={onFocus} value={value}>
    {children}
  </CellSelectBase>
)

CellSelect.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string
}

export default CellSelect
