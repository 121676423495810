import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import PopOverArrow from './PopOverArrow'

const PopOverBorder = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 ${props => (props.wideShadow ? 300 : 2)}px
    ${props => (props.wideShadow ? 10 : 0)}px rgba(128, 128, 128, 0.5);
  width: 100%;
  height: 100%;

  @media print {
    
  }
`

const PopOverDialogBase = styled.div`
  width: ${p => p.theme.sizes.popoverWidth}px;
  max-height: ${p => p.theme.sizes.popOverMaxHeight};
  z-index: 2;
  
  @media print {
    max-height: 100%;
    width: ${p => p.theme.sizes.popoverWidth * 0.7}px;
    margin: 0 auto;
  }
`

const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: ${p => p.theme.sizes.popOverBorderRadius}px;
  border-top-right-radius: ${p => p.theme.sizes.popOverBorderRadius}px;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  max-height: ${p => p.theme.sizes.popOverMaxHeight};
  
  @media print {
    max-height: 100%;
  }
`

const Arrow = styled(PopOverArrow).attrs(p => ({
  left: p.showOnLeft,
  right: !p.showOnLeft
}))`
  top: ${props => props.arrowTopPosition}px;
  position: absolute;
  left: ${p => (p.showOnLeft ? 0 : 'auto')};
  right: ${p => (!p.showOnLeft ? '-11px' : 'auto')};
`

Arrow.propTypes = {
  showOnLeft: PropTypes.bool,
  arrowTopPosition: PropTypes.number
}

const PopOverDialog = ({
  children,
  style,
  className,
  showArrows = false,
  wideShadow = false,
  arrowTopPosition = 0,
  showArrowOnLeft = false
}) => {
  return (
    <PopOverDialogBase style={style} className={className}>
      {showArrows && (
        <Arrow
          showOnLeft={showArrowOnLeft}
          arrowTopPosition={arrowTopPosition}
        />
      )}
      <PopOverBorder
        wideShadow={wideShadow}
        arrowTopPosition={arrowTopPosition}
      >
        <ScrollContainer>{children}</ScrollContainer>
      </PopOverBorder>
    </PopOverDialogBase>
  )
}

PopOverDialog.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
  showArrows: PropTypes.bool,
  wideShadow: PropTypes.bool,
  showArrowOnLeft: PropTypes.bool,
  arrowTopPosition: PropTypes.number
}

export default PopOverDialog
