import styled from 'styled-components'

export default styled.div`
  height: 16px;
  color: ${p => p.theme.colors.darkGray};
  ${p => p.theme.fonts.family.default};
  font-size: 13.5px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  margin-left: 3px;
  margin-bottom: 6px;
  border-bottom: ${p => p.underlined && p.theme.border.default};

  @media print {
    font-size: ${p => p.theme.print.fonts.size.small}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.small};
    height: auto;
    margin-bottom: ${p => p.theme.print.spacing.cell.margin}px;
    margin-left: 0;
  }
`
