import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {useWindowSize} from '../../../hooks/useWindowSize'

const LayoutContentRightBase = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${p =>
    p.headerPresent
      ? p.theme.sizes.headerHeight +
        'px ' +
        (p.mainHeight -
          p.theme.sizes.headerHeight -
          p.theme.sizes.footerHeight) +
        'px ' +
        p.theme.sizes.footerHeight +
        'px'
      : p.mainHeight -
        p.theme.sizes.footerHeight +
        'px ' +
        p.theme.sizes.footerHeight +
        'px'};

  @media print {
    display: block;
    margin: 0 auto;
    padding: ${p => p.theme.print.spacing.cell.padding * 2}px;
    max-width: 100%;
    width: 210mm; // Standard A4 width
    position: relative;
    left: 10mm;
  }
`

const LayoutMain = styled.div`
  display: grid;
  grid-template-columns: ${p => p.renderPlaceHolderLeft && '1fr'} auto ${p =>
      p.renderPlaceHolderRight && '1fr'};

  @media print {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
`

const LayoutContentRight = ({
  renderFooter,
  renderHeader,
  renderMain,
  renderPlaceHolderLeft,
  renderPlaceHolderRight
}) => {
  const {innerHeight} = useWindowSize()

  return (
    <LayoutContentRightBase
      headerPresent={renderHeader && renderHeader() !== null}
      mainHeight={innerHeight}
    >
      {renderHeader && renderHeader()}
      <LayoutMain
        renderPlaceHolderLeft={renderPlaceHolderLeft}
        renderPlaceHolderRight={renderPlaceHolderRight}
      >
        {renderPlaceHolderLeft && renderPlaceHolderLeft()}
        {renderMain()}
        {renderPlaceHolderRight && renderPlaceHolderRight()}
      </LayoutMain>
      {renderFooter && renderFooter()}
    </LayoutContentRightBase>
  )
}

LayoutContentRight.propTypes = {
  renderFooter: PropTypes.func,
  renderHeader: PropTypes.func,
  renderMain: PropTypes.func.isRequired,
  renderPlaceHolderLeft: PropTypes.func,
  renderPlaceHolderRight: PropTypes.func
}

export default LayoutContentRight
