import PropTypes from 'prop-types'
import React from 'react'
import styled, { ThemeProvider, useTheme, ThemeContext } from 'styled-components'
import Portal from '../../core/components/Portal'
import { useContext } from 'react'

// z-index of uppermost modal is 201
const LogoModalBase = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme?.colors?.background || '#ffffff'};
  z-index: 202;
`

const Branding = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Icon = styled.img`
  width: 76px;
  height: 76px;
`

const LogoModalContent = styled.div``

const BrandName = styled.div`
  text-align: center;
  margin-top: 3px;
  ${p => p.theme.fonts.family.default};
  font-weight: ${p => p.theme.fonts.weight.bold};
`

const LogoModal = ({brandName, isOpen}) => {
  const theme = useContext(ThemeContext)
  
  if (!isOpen) {
    return null
  }

  return (
    <Portal isOpen={isOpen}>
      <ThemeProvider theme={theme}>
        <LogoModalBase>
          <LogoModalContent>
            <Branding>
              <Icon
                src={`${process.env.PUBLIC_URL}/assets/icons/resize-hide-logo.svg`}
              />
              <BrandName>{brandName}</BrandName>
            </Branding>
          </LogoModalContent>
        </LogoModalBase>
      </ThemeProvider>
    </Portal>
  )
}

LogoModal.propTypes = {
  brandName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default LogoModal
