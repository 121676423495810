import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {HeaderRow} from './HeaderRow'
import TitleRow from './TitleRow'

const HeaderRowsBase = styled.div`
  @media print {
    width: 100%;
    max-width: ${p => p.theme.print.sizes.dataGridLeftColumnWidth + 500}px;
    padding: 0;
    margin: 0;
  }
`

const Text = styled.span`
  color: ${p => p.theme.colors.darkGray};
  ${p => p.theme.fonts.family.tall};
  font-size: ${p => p.theme.fonts.size.big}px;
`
const IngredientSearchRow = styled(HeaderRow)`
  justify-content: center;
`

const HeaderRows = ({compositeTitle, compositeType, selectedComponent}) => {
  const [t] = useTranslation()

  return (
    <HeaderRowsBase>
      <TitleRow
        selectedComponent={selectedComponent}
        compositeTitle={compositeTitle}
        compositeType={compositeType}
        t={t}
      />
      {!selectedComponent && (
        <IngredientSearchRow>
          <Text>{t('headerRows.ingredientSearchRow.title')}</Text>
        </IngredientSearchRow>
      )}
    </HeaderRowsBase>
  )
}

HeaderRows.propTypes = {
  compositeTitle: PropTypes.string.isRequired,
  compositeType: PropTypes.string.isRequired,
  selectedComponent: PropTypes.object
}

export default HeaderRows
