import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Cell from './Cell'

const CellLeftBase = styled(Cell)`
  border-bottom: ${p => p.theme.border.thin};
  border-right: ${p => p.theme.border.thin};

  @media print {
    padding: 2px 0;
    width: ${p => p.theme.print.sizes.dataGridLeftColumnWidth * 1}px;
  }
`

const CellLeft = ({children, className, componentIndex, style}) => (
  <CellLeftBase
    id={`CellLeftBase${componentIndex === undefined ? '' : componentIndex}`}
    className={className}
    column={2}
    style={style}
  >
    {children}
  </CellLeftBase>
)

CellLeft.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  componentIndex: PropTypes.number,
  style: PropTypes.object
}

export default CellLeft
