import PropTypes from 'prop-types'
import React from 'react'
import range from 'lodash/range'
import styled from 'styled-components'

import {
  IconCO2Inactive,
  IconCoinActive,
  IconCoinInactive,
  IconHeartActive,
  IconHeartInactive,
  IconWaterActive,
  IconWaterInactive
} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'

const IndicatorGroupBase = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: ${props => props.width}px;

  @media print {
  }
`

const IndicatorIcon = styled(Icon)`
  @media print {
    width: 28px !important;
    height: 28px !important;
  }
`

const iconSources = {
  co2: {
    ratingActive: `${process.env.PUBLIC_URL}/assets/icons/indicator-co2-active.svg`,
    ratingInactive: IconCO2Inactive
  },
  health: {
    ratingActive: IconHeartActive,
    ratingInactive: IconHeartInactive
  },
  profit: {
    ratingActive: IconCoinActive,
    ratingInactive: IconCoinInactive
  },
  water: {
    ratingActive: IconWaterActive,
    ratingInactive: IconWaterInactive
  }
}

const IndicatorGroup = ({indicatorType, granularity = 5, score}) => {
  const numIcons = granularity
  const width = numIcons * 27
  return (
    <IndicatorGroupBase width={width}>
      {range(0, numIcons).map((_, index) => {
        const src =
          score <= index
            ? iconSources[indicatorType].ratingInactive
            : iconSources[indicatorType].ratingActive
        const id =
          score <= index
            ? `icon${indicatorType}Inactive${index}`
            : `icon${indicatorType}Active${index}`
        return (
          <IndicatorIcon
            dataTestId={id}
            key={index}
            src={src}
            width="60px"
            height="60px"
          />
        )
      })}
    </IndicatorGroupBase>
  )
}

IndicatorGroup.propTypes = {
  indicatorType: PropTypes.string.isRequired,
  granularity: PropTypes.number,
  score: PropTypes.number
}

export default IndicatorGroup
