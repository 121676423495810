import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import DraftEditor from '../../core/components/DraftEditor'

const InstructionEditorBase = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: ${p => p.theme.sizes.dataGridRightColumnMaxWidth};
  padding-top: 20px;
  padding-bottom: ${p => p.theme.sizes.instuctionPadding}px;
  padding-left: ${p => p.theme.sizes.instuctionPadding}px;

  @media print {
    padding-top: ${p => p.theme.print.spacing.cell.padding}px;
    padding-bottom: ${p => p.theme.print.spacing.cell.padding}px;
    padding-left: ${p => p.theme.print.spacing.cell.padding}px;
  }
`

const InstructionEditor = ({contentState, editedInstructionIndex, onBlur}) => (
  <InstructionEditorBase id={`InstructionEditorBase${editedInstructionIndex}`}>
    <DraftEditor contentState={contentState} onBlur={onBlur} />
  </InstructionEditorBase>
)

InstructionEditor.propTypes = {
  contentState: PropTypes.object,
  editedInstructionIndex: PropTypes.number.isRequired,
  onBlur: PropTypes.func.isRequired
}

export default InstructionEditor
