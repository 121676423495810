import styled from 'styled-components'

const Row = styled.div`
  display: grid;
  grid-template-columns:
    minmax(${p => p.theme.sizes.dataGridLeftColumnWidth}px, 2fr)
    5fr;
    
  @media print {
    grid-template-columns: 
      minmax(${p => p.theme.print.sizes.dataGridLeftColumnWidth}px, 1.5fr) 
      8fr;
    min-height: 0;
    height: auto;
    padding: ${p => p.theme.print.spacing.cell.padding}px 0;
  }
`

export default Row
