import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {
  AwardAnimal,
  AwardEaternity,
  AwardEnergy,
  AwardHealth,
  AwardLocal,
  AwardProfit,
  AwardSeason,
  AwardWater,
  AwardForest
} from '../../../assets/icons'
import Icon from '../../icons/components/Icon'

const AwardIndicatorsBase = styled.div`
  margin-left: auto;
  margin-right: ${p => p.theme.sizes.popoverWidth + 120}px;
  display: flex;

  @media print {
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 0;
    justify-content: flex-start;
  }
`

const AwardIcon = styled(Icon)`
  margin-right: -17px;

  @media print {
    margin-right: 0;
    width: 40px !important;
    height: 40px !important;
    margin-left: ${p => p.theme.print.spacing.cell.padding}px;
  }
`

const AwardIndicators = ({
  awards: {
    co2: {hasAward: hasCo2Award},
    health: {hasAward: hasHealthAward},
    water: {hasAward: hasWaterAward},
    rainforestLabel: {hasAward: hasRainforestAward}
  },
  webEntryDomain
}) => {
  const indicatorData = [
    {
      icon: `${process.env.PUBLIC_URL}/assets/icons/award-climate.svg`,
      show: hasCo2Award
    },
    {
      icon: AwardHealth,
      show: webEntryDomain === 'eaternity' && hasHealthAward
    },
    {
      icon: AwardWater,
      show: webEntryDomain === 'eaternity' && hasWaterAward
    },
    {
      icon: AwardEnergy,
      show: false
    },
    {
      icon: AwardForest,
      show: webEntryDomain === 'eaternity' && hasRainforestAward
    },
    {
      icon: AwardAnimal,
      show: false
    },
    {
      icon: AwardSeason,
      show: false
    },
    {
      icon: AwardLocal,
      show: false
    },
    {
      icon: AwardProfit,
      show: false
    },
    {
      icon: AwardEaternity,
      show: false
    }
  ]

  return (
    <AwardIndicatorsBase>
      {indicatorData.map(({icon, show}, index) =>
        show ? (
          <AwardIcon src={icon} width={'60px'} height={'60px'} key={index} />
        ) : null
      )}
    </AwardIndicatorsBase>
  )
}

AwardIndicators.propTypes = {
  awards: PropTypes.object.isRequired,
  webEntryDomain: PropTypes.string.isRequired
}

export default AwardIndicators
