import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  line-height: 60px;
  font-weight: 600;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: ${p => (p.isLast ? '60px' : '-1px')};
  border-top: ${p => p.theme.border.default};
  margin-top: ${p => p.isFirst && '-1px'};
  border-bottom: ${p => p.isLast && p.theme.border.default};

  @media print {
    line-height: ${p => p.theme.print.sizes.factRowHeight}px;
    padding-left: ${p => p.theme.print.spacing.cell.padding}px;
    padding-right: ${p => p.theme.print.spacing.cell.padding}px;
    margin-bottom: ${p => (p.isLast ? p.theme.print.spacing.cell.padding * 2 : 0)}px;
    margin-top: 0;
    border: none;
  }
`

export default Row
