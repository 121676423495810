import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import Cell from './Cell'

const HeaderCellLeftBase = styled(Cell)`
  border-bottom: ${p => p.theme.border.thin};
  border-right: ${p => p.theme.border.thin};

  @media print {
    border-bottom: none;
    border-right: none;
    text-align: left;
    font-size: ${p => p.theme.print.fonts.size.bigger}px;
    line-height: ${p => p.theme.print.fonts.lineHeight.big};
    padding-left: 20px;
    width: ${p => p.theme.print.sizes.dataGridLeftColumnWidth}px;
    margin-left: -15px;
    
    // Target any input inside
    input {
      text-align: left;
      font-size: ${p => p.theme.print.fonts.size.bigger}px;
      line-height: ${p => p.theme.print.fonts.lineHeight.big};
      padding-left: 0;
      width: 100%;
      margin-left: 0;
    }
  }
`

const HeaderCellLeft = ({children, style, className}) => (
  <HeaderCellLeftBase style={style} className={className} column={1}>
    {children}
  </HeaderCellLeftBase>
)

HeaderCellLeft.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  style: PropTypes.object,
  className: PropTypes.string
}

export default HeaderCellLeft
